import React from 'react';

import { VendorContext, VendorDispatchContext } from './VendorContext';

export { default as VendorProvider } from './VendorContext';

export { VendorContext, VendorDispatchContext };

export function useVendor() {
  const context = React.useContext(VendorContext);
  if (context === undefined) {
    throw new Error('The useVendor hook must be used within a VendorProvider.');
  }
  return context;
}

export function useVendorDispatch() {
  const context = React.useContext(VendorDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useVendorDispatch hook must be used within a VendorProvider.'
    );
  }
  return context;
}
