import { Button } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';
import { Avatar } from '@mui/material';

export const StyledCreditAvatar = styled(Avatar, {
  '&.MuiAvatar-root': {
    backgroundColor: '$Neutral01',
  },
});

export const StyledCreditContainer = styled('section', {
  padding: 24,
  paddingBottom: 88 + 24,
});

export const StyledInputRow = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '16px 0',
});

export const StyledPaymentButtonTextContainer = styled('span', {
  display: 'flex',
});

export const StyledPaymentMethodButton = styled(Button, {
  borderRadius: 0,
  borderWidth: 1,
  boxShadow: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  padding: 16,
});

export const StyledPaymentMethodsContent = styled('div', {
  backgroundColor: '$Neutral05',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-bewteen',
  paddingBottom: 100,
});

export const StyledPaymentMethodsContentBottom = styled('div', {
  backgroundColor: 'var(--BackgroundBase1)',
  // marginTop: 10,
  padding: 24,
  paddingTop: 0,
});

export const StyledPaymentMethodsContentTop = styled('div', {
  backgroundColor: 'var(--BackgroundBase1)',
  padding: 24,
  paddingBottom: 0,
});

export const StyledStickyBottom = styled('div', {
  alignItems: 'center',
  backgroundColor: 'var(--BackgroundBase1)',
  display: 'flex',
  bottom: 0,
  height: 88,
  justifyContent: 'space-between',
  left: 0,
  padding: '16px 24px',
  position: 'absolute',
  shadow: 2,
  width: '100%',
  zIndex: 1,
  '@sm': {
    height: 72,
    padding: '8px 16px',
  },
});
