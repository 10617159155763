import React, { useState } from 'react';
import {
  Button,
  type ClickButtonEvent,
  Loading,
  Modal,
  Text,
} from '@goodfynd/react-web.lib.ui';
import TextField from '@mui/material/TextField';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import strings from '../../config/strings';
import { useStores } from '../../stores';

import StripeTextField from './StripeTextField';
import {
  StyledCreditContainer,
  StyledInputRow,
  StyledStickyBottom,
} from './styles';
import type { StripeAddCardProps, StripeCreditCardFormProps } from './types';

export const logEvent =
  (name: string): any =>
  (event: any) => {
    console.log(`[${name}]`, event);
  };

export function StripeCreditCardForm({
  setPaymentMethod,
  ...props
}: StripeCreditCardFormProps) {
  const elements = useElements();
  const {
    orderStore: { customer },
  } = useStores();
  const stripe = useStripe();
  const [busy, setBusy] = useState(false);
  const [postal, setPostal] = useState(customer?.postalCode);
  const [errorMessage, setErrorMessage] = useState('');

  const [state, setState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange =
    (field: keyof typeof state, errorField: keyof typeof state) =>
    ({
      complete,
      error = { message: null },
    }: {
      complete: boolean;
      error: any;
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };

  const handleSubmit = async (event: ClickButtonEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setBusy(true);

    const card = elements.getElement(CardNumberElement);
    if (card == null) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: customer?.fullName,
        address: {
          postal_code: customer?.postalCode,
        },
      },
    });

    if (payload === null) {
      return;
    }

    if (payload.error || !payload.paymentMethod.card) {
      console.warn('[error]', payload.error);
      setErrorMessage(payload.error?.message as string);
      setPaymentMethod?.(undefined);
      setBusy(false);
    } else {
      console.debug(
        '[PaymentMethod]',
        payload.paymentMethod.id,
        payload.paymentMethod.billing_details,
        payload.paymentMethod.card
      );
      setErrorMessage('');

      const { id, card: paymentCard } = payload.paymentMethod;
      setPaymentMethod?.({
        ...paymentCard,
        cardType: paymentCard.brand,
        expirationDate: `${paymentCard.exp_month}/${paymentCard.exp_year}`,
        expirationMonth: paymentCard.exp_month.toString(),
        expirationYear: paymentCard.exp_year.toString(),
        id,
        imageUrl: '',
        isDefault: false,
        isExpired: false,
        label: `${paymentCard.brand}-${paymentCard.last4}`,
        lastFour: paymentCard.last4,
        mask: `${[...Array(12)].join('*')}${paymentCard.last4}`,
        token: id,
      });

      props.close?.();
    }
  };

  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <form>
      <StyledInputRow>
        <StripeTextField
          error={Boolean(cardNumberError)}
          id="cardNumber"
          label="Card Number"
          labelErrorMessage={cardNumberError}
          onBlur={logEvent('blur')}
          onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
          onFocus={logEvent('focus')}
          stripeElement={CardNumberElement}
          style={{
            flex: 2,
            marginRight: 8,
          }}
        />
        <StripeTextField
          error={Boolean(cvcError)}
          id="cvc"
          label="CVC"
          labelErrorMessage={cvcError}
          onBlur={logEvent('cvc:blur')}
          onChange={onElementChange('cvcComplete', 'cvcError')}
          onFocus={logEvent('cvc:focus')}
          stripeElement={CardCvcElement}
          style={{
            flex: 1,
          }}
        />
      </StyledInputRow>

      <StyledInputRow>
        <StripeTextField
          error={Boolean(expiredError)}
          id="expiry"
          label="Expiration"
          labelErrorMessage={expiredError}
          onBlur={logEvent('expiry:blur')}
          onChange={onElementChange('expiredComplete', 'expiredError')}
          onFocus={logEvent('expiry:focus')}
          stripeElement={CardExpiryElement}
          style={{
            flex: 1,
            marginRight: 8,
          }}
        />

        <TextField
          id="postal"
          label="Postal Code"
          placeholder="12345"
          required
          value={postal}
          onChange={(e) => {
            setPostal(e.target.value);
          }}
          style={{
            flex: 1,
          }}
        />
      </StyledInputRow>

      {errorMessage && <Text>{errorMessage}</Text>}

      <StyledStickyBottom>
        <Button disabled={busy} fullWidth onClick={handleSubmit} type="submit">
          {busy ? <Loading type="line" /> : strings.labels.addCreditCard}
        </Button>
      </StyledStickyBottom>
    </form>
  );
}

export default function StripeAddCard({
  setPaymentMethod,
  ...props
}: StripeAddCardProps) {
  return (
    <Modal
      header={<Text as="modalHeader">{strings.titles.addNewCard}</Text>}
      {...props}
    >
      <StyledCreditContainer>
        <StripeCreditCardForm {...props} setPaymentMethod={setPaymentMethod} />
      </StyledCreditContainer>
    </Modal>
  );
}
