import { useQuery } from 'react-query';

import config from '../config';
import CmsApi from '../services/api/cms-api';

import type { ComponentType } from '../utils/cms-util';

import type { ButterCmsPageType } from '../services/api/types';
const api = new CmsApi();

type ButterCmsPageData = {
  fields: CmsPageProps<ComponentType>;
};

export default function useCmsPage(slug: string, pageType?: ButterCmsPageType) {
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    ButterCmsPageData,
    unknown
  >(
    [config.queryKeys.cms, slug],
    () => {
      return api.getPageData(slug, pageType);
    },
    {
      enabled: !!slug,
    }
  );

  const { body, seo, header, title, footer, page_type } = data?.fields || {};
  return {
    page_type,
    footer,
    header,
    title,
    body,
    isFetched,
    isFetching,
    isLoading,
    refetch,
    seo,
  };
}
