import { useMutation, useQueryClient } from 'react-query';

import { useAccountApi } from '../hooks/api';
import { DeleteProfileRequest } from '../types/user';
import config from '../config';

const useDeleteProfile = () => {
  const queryClient = useQueryClient();
  const accountApi = useAccountApi();

  return useMutation(
    (profile: DeleteProfileRequest) => accountApi.deleteProfile(profile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(config.queryKeys.auth);
      },
    }
  );
};

export default useDeleteProfile;
