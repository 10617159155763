import React, { useCallback, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import type { PaymentRequest } from '@stripe/stripe-js';

import config from '../../config';
import { useStores } from '../../stores';
import type { CreditCard } from '../../types/orders';

import PaymentMethods from './PaymentMethods';
import StripeAddCard from './StripeAddCard';
import type { CheckoutOptions } from './types';

export default function useCheckout({
  amount,
  vendors = 'Goodfynd Checkout',
}: CheckoutOptions) {
  const {
    orderStore: { customer },
  } = useStores();
  const stripe = useStripe();

  const [paymentMethod, setPaymentMethod] = useState<CreditCard | undefined>(
    customer?.creditCards.find((card) => card.isDefault) ||
      ((customer?.creditCards?.length || 0) > 0
        ? customer?.creditCards[0]
        : undefined)
  );
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [showCreditForm, toggleCreditForm] = useState(false);
  const [showPaymentMethods, togglePaymentMehtods] = useState(false);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: config.env.COUNTRY_CODE,
        currency: config.env.CURRENCY_CODE.toLowerCase(),
        total: {
          amount: Math.round(amount * 100),
          label: vendors,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, vendors]);

  const renderCreditModal = useCallback(() => {
    return (
      <StripeAddCard
        open={showCreditForm}
        setPaymentMethod={setPaymentMethod}
        close={() => {
          togglePaymentMehtods(false);
          toggleCreditForm(false);
        }}
      />
    );
  }, [showCreditForm]);

  const renderPaymentMethods = useCallback(() => {
    return (
      <PaymentMethods
        cards={customer?.creditCards as CreditCard[]}
        close={() => togglePaymentMehtods(false)}
        open={showPaymentMethods}
        setPaymentMethod={setPaymentMethod}
        showCreditForm={() => toggleCreditForm(true)}
      />
    );
  }, [paymentMethod, showPaymentMethods]);

  return {
    customer,
    openCreditModal: () => toggleCreditForm(true),
    openPaymentMethodsModal: () => togglePaymentMehtods(true),
    paymentMethod,
    paymentRequest,
    renderCreditModal,
    renderPaymentMethods,
    setPaymentMethod,
  };
}
