import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import config from '../../config';
import { useVendorApi } from '../../hooks/api';
import { vendorUtil } from '../../utils';

import type { VendorResponse } from '../../services/api/types';
import type {
  VendorContextValue,
  VendorDispatchContextValue,
  VendorProviderProps,
} from './types';
export const VendorContext = createContext<VendorContextValue | undefined>(
  undefined
);
export const VendorDispatchContext = createContext<
  VendorDispatchContextValue | undefined
>(undefined);

export default observer(function VendorProvider({
  children,
}: VendorProviderProps) {
  const router = useRouter();
  const { id } = router.query;

  // Fetch vendor profile info
  const api = useVendorApi();
  const profileId = id as string;
  const {
    data: { vendor } = {} as VendorResponse,
    isError,
    isFetched,
    isFetching,
    isLoading,
    refetch: refetchVendor,
  } = useQuery<VendorResponse, unknown>(
    [config.queryKeys.vendor, profileId],
    () => {
      return router.pathname.includes('schedules')
        ? api.getScheduleDetails(profileId)
        : api.get(profileId);
    },
    {
      enabled: !!profileId,
      refetchOnMount: true,
    }
  );

  const value = useMemo(
    () => ({
      isError,
      isFetched,
      isFetching,
      isLoading,
      vendor,
      vendorId: profileId,
    }),
    [isError, isFetched, isFetching, isLoading, profileId, vendor]
  );

  return (
    <VendorContext.Provider value={value}>
      <VendorDispatchContext.Provider
        value={{
          isCurrentVendor: useCallback(
            (entity) => vendorUtil.idEquals(profileId, entity),
            [profileId]
          ),
          refetchVendor,
        }}
      >
        {children}
      </VendorDispatchContext.Provider>
    </VendorContext.Provider>
  );
});
